import {DataroomAuditEventType} from "./LoanApplicationEnum";
import Resources from "../../Resources";

export const AuditEventType = {
    ...DataroomAuditEventType,
    ...{
        OPPORTUNITY_LIST: {
            code: 'OPPORTUNITY_LIST',
            label: 'Open opportunity list',
            desc: 'User tries to view list of available opportunities'
        },
        OPPORTUNITY_VIEW: {
            code: 'OPPORTUNITY_VIEW',
            label: 'View opportunity details',
            desc: 'User tries to view details of opportunity'
        },
        APPROVE_CREDITOR_BY_BORROWER: {
            code: 'APPROVE_CREDITOR_BY_BORROWER',
            label: 'Borrower approved Debt Investor',
            desc: 'Borrower approved Debt Investor'
        },
        LOAN_APPLICATION_WORKING_ON_DRAFT: {
            code: 'LOAN_APPLICATION_WORKING_ON_DRAFT',
            label: 'Working on Loan application draft',
            desc: 'Working on Loan application draft'
        },
        LOAN_APPLICATION_CREATE: {
            code: 'LOAN_APPLICATION_CREATE',
            label: 'Loan application created',
            desc: 'Loan application created'
        },
        LOAN_APPLICATION_LIST: {
            code: 'LOAN_APPLICATION_LIST',
            label: 'Open loan application list',
            desc: 'Loan application list'
        },
        LOAN_APPLICATION_VIEW: {
            code: 'LOAN_APPLICATION_VIEW',
            label: 'Open loan application details',
            desc: 'Loan application details'
        },
        LOAN_APPLICATION_VIEW_CREDITORS: {
            code: 'LOAN_APPLICATION_VIEW_CREDITORS',
            label: 'View a list of Debt Investors for a loan application',
            desc: 'View Debt Investors for Loan application'
        },
        LOAN_APPLICATION_DOWNLOAD_DOC: {
            code: 'LOAN_APPLICATION_DOWNLOAD_DOC',
            label: 'Download a loan application document',
            desc: 'Download Loan application document'
        },
        LOAN_APPLICATION_DATAROOM_VIEW: {
            code: 'LOAN_APPLICATION_DATAROOM_VIEW',
            label: 'View dataroom for a loan application',
            desc: 'View dataroom for Loan application'
        },
        LOAN_APPLICATION_DATAROOM_CREATE_ARCHIVE: {
            code: 'LOAN_APPLICATION_DATAROOM_CREATE_ARCHIVE',
            label: 'Create dataroom archive for a loan application',
            desc: 'Create dataroom archive for Loan application'
        },
        LOAN_APPLICATION_DATAROOM_DOWNLOAD_ARCHIVE: {
            code: 'LOAN_APPLICATION_DATAROOM_DOWNLOAD_ARCHIVE',
            label: 'Download dataroom archive for a loan application',
            desc: 'Download dataroom archive for Loan application'
        },
        APPROVE_CREDITORS_BY_BORROWER: {
            code: 'APPROVE_CREDITORS_BY_BORROWER',
            label: 'Borrower approved Debt Investor',
            desc: 'Borrower approved Debt Investor'
        },
        LOAN_APPLICATION_DATAROOM_UPLOADED_DOC_BY_BORROWER: {
            code: 'LOAN_APPLICATION_DATAROOM_UPLOADED_DOC_BY_BORROWER',
            label: 'Borrower uploaded document',
            desc: 'Borrower uploaded document'
        },
        CREDITOR_APPLICATION_LIST: {
            code: 'CREDITOR_APPLICATION_LIST',
            label: 'Open Debt Investors application list',
            desc: 'Debt Investors application list'
        },
        CREDITOR_APPLICATION_VIEW: {
            code: 'CREDITOR_APPLICATION_VIEW',
            label: 'Open Debt Investor application details',
            desc: 'Debt Investor application details'
        },
        CREDITOR_APPLICATION_WORKING_ON_DRAFT: {
            code: 'CREDITOR_APPLICATION_WORKING_ON_DRAFT',
            label: 'Working on Debt Investor application draft',
            desc: 'Working on Debt Investor application draft'
        },
        CREDITOR_APPLICATION_CREATE: {
            code: 'CREDITOR_APPLICATION_CREATE',
            label: 'Debt Investor application created',
            desc: 'Debt Investor application created'
        },
        OPPORTUNITY_REQUEST_CALL: {
            code: 'OPPORTUNITY_REQUEST_CALL',
            label: 'Request call regarding Opportunity',
            desc: 'Request call regarding Opportunity'
        },
        OPPORTUNITY_DOWNLOAD_NDA: {
            code: 'OPPORTUNITY_DOWNLOAD_NDA',
            label: 'Download actual NDA',
            desc: 'Download actual NDA'
        },
        OPPORTUNITY_UPLOAD_NDA: {
            code: 'OPPORTUNITY_UPLOAD_NDA',
            label: 'Upload creditor NDA',
            desc: 'Upload creditor NDA'
        },
        OPPORTUNITY_DOWNLOAD_DOC: {
            code: 'OPPORTUNITY_DOWNLOAD_DOC',
            label: 'Download Opportunity document',
            desc: 'Download Opportunity document'
        },
        OPPORTUNITY_DATAROOM_VIEW: {
            code: 'OPPORTUNITY_DATAROOM_VIEW',
            label: 'View dataroom of an Opportunity',
            desc: 'View dataroom of an Opportunity'
        },
        OPPORTUNITY_DATAROOM_CREATE_ARCHIVE: {
            code: 'OPPORTUNITY_DATAROOM_CREATE_ARCHIVE',
            label: 'Create dataroom archive for Opportunity',
            desc: 'Create dataroom archive for Opportunity'
        },
        OPPORTUNITY_DATAROOM_DOWNLOAD_ARCHIVE: {
            code: 'OPPORTUNITY_DATAROOM_DOWNLOAD_ARCHIVE',
            label: 'Download dataroom archive for Opportunity',
            desc: 'Download dataroom archive for Opportunity'
        },
        OPPORTUNITY_UPDATE: {
            code: 'OPPORTUNITY_UPDATE',
            label: 'Opportunity update',
            desc: 'Opportunity update'
        },
    }
}

export const AuditObjectType = {
    OPPORTUNITY: {
        code: 'OPPORTUNITY',
        label: 'Funding opportunity',
        reference: Resources.OPPORTUNITIES.name,
        showLink: "/loanApplications/${refId}/show/4"
    },
    LOAN_APPLICATION: {
        code: 'LOAN_APPLICATION',
        label: 'Loan application',
        reference: Resources.LOAN_APPLICATIONS.name,
        showLink: "/loanApplications/${id}/show",
        getManyResource: "loanApplications/findBriefByIDs"
    },
    LOAN_APPLICATION_DOCUMENT: {
        code: 'LOAN_APPLICATION_DOCUMENT',
        label: 'Loan application document',
        reference: Resources.LOAN_APPLICATIONS.name,
        showLink: "/loanApplications/${id}/show/3",
        source: "params.applicationId",
        getManyResource: "loanApplications/findBriefByIDs"
    },
    OPPORTUNITY_DOCUMENT: {
        code: 'OPPORTUNITY_DOCUMENT',
        label: 'Opportunity document',
        reference: Resources.OPPORTUNITIES.name,
        showLink: "/loanApplications/${refId}/show/3",
        source: "params.relId"
    },
    CREDITOR_APPLICATION: {
        code: 'CREDITOR_APPLICATION',
        label: 'Debt Investor application',
        reference: Resources.CREDITOR_APPLICATIONS.name,
        showLink: "/creditorApplications/${id}/show"
    },
}