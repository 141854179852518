import PropTypes from 'prop-types'
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {
    BooleanField,
    Button,
    Datagrid,
    DateField,
    Form,
    FunctionField,
    ListContextProvider,
    TextField,
    useDataProvider,
    useList,
    useNotify,
    useRefresh,
    useTheme,
} from "react-admin";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import React, {useEffect, useState} from "react";
import {Grid, Typography} from "@mui/material";
import Resources from "../../Resources";
import {onError, orElse, toLondonDateTime} from "../../common/utils";
import FormattedAmountField from "../controls/FormattedAmountField";
import {LoanApplicationCreditorStatus} from "../dicts/LoanApplicationCreditorRelStatusEnum";
import EnumDictField from "../controls/EnumDictField";
import {PartyField} from "../controls/PartyField";
import DateTimeFieldTZ from "../controls/DateFieldTZ";
import getCountries, {getCountryByCode} from "../../common/countries";
import {LoanApplicationStatus} from "../dicts/LoanApplicationEnum";
import {useFormContext} from "react-hook-form";
import LegalEntityInput from "../controls/LegalEntityInput";
import {NDAStatus} from "../dicts/NDAStatusEnum";

const CustomEmpty = () => <div className="grid-no-data-found">No data found</div>;

const CallReportsGrid = (props) => {
    const {data, label} = props
    const callReportListContext = useList({data})
    const [theme] = useTheme();
    return (
        <>
            {label && <Typography className={`grid-label grid-label-${theme}`}>{label}</Typography>}
            <ListContextProvider value={callReportListContext}>
                <Datagrid rowClick={false} bulkActionButtons={false} className={`grid-on-form grid-on-form-${theme}`} empty={<CustomEmpty/>}>
                    <DateField source="communicationDate" locales="en-GB"/>
                    <TextField source="communicationType"/>
                    <FunctionField label="Contact Person" render={(record) => (
                        <ul className="datagrid-ul">
                            {record.contactPersons?.map(v =>
                                <li>{
                                    v.participant?.firstName + " " + v.participant?.lastName
                                    + " (" + v.participantEmployer?.fullCompanyName + ")"}</li>)}
                        </ul>
                    )}/>
                    <FunctionField label="Monivolt Rep" render={(record) => (
                        <ul className="datagrid-ul">
                            {record.monivoltReps?.map(v =>
                                <li>{
                                    v.participant?.firstName + " " + v.participant?.lastName
                                    + " (" + v.participant?.userLogin + ")"}</li>)}
                        </ul>
                    )}/>
                </Datagrid>
            </ListContextProvider>
        </>
    )
}

const OpportunitiesGrid = (props) => {
    const {data, label, displayCreditor, displayBorrower} = props
    const opportunitiesListContext = useList({data})
    const [theme] = useTheme();
    return (
        <>
            {label && <Typography className={`grid-label grid-label-${theme}`}>{label}</Typography>}
            <ListContextProvider value={opportunitiesListContext}>
                <Datagrid rowClick={false} bulkActionButtons={false} className={`grid-on-form grid-on-form-${theme}`} empty={<CustomEmpty/>}>
                    <DateTimeFieldTZ source="createdDate" locales="en-GB"/>
                    <FormattedAmountField
                        label="Loan Amount"
                        source="loanApplication.loanAmount"
                        currencyAttr="loanApplication.currency"
                        options={{maximumFractionDigits: 0}}
                    />
                    <EnumDictField enum={LoanApplicationCreditorStatus} source="statusCode"/>
                    {displayCreditor &&
                        <PartyField source="creditorId" partyType="LEGAL_ENTITY" label="Debt Investor"/>}
                    {displayBorrower &&
                        <PartyField source="loanApplication.partyId" partyType="LEGAL_ENTITY" label="Borrower"/>}
                    <EnumDictField enum={NDAStatus} source="ndaStatusCode" label="NDA"/>
                    <BooleanField source="loiIsAccepted" label="Access to Full DD"/>
                    <BooleanField source="isCreditorVerified" label="Is Debt Investor verified?"/>
                </Datagrid>
            </ListContextProvider>
        </>
    )
}

const LoanApplicationsGrid = (props) => {
    const {data, label} = props
    const loanApplicationsListContext = useList({data})
    const [theme] = useTheme();
    return (
        <>
            {label && <Typography className={`grid-label grid-label-${theme}`}>{label}</Typography>}
            <ListContextProvider value={loanApplicationsListContext}>
                <Datagrid rowClick={false} bulkActionButtons={false} className={`grid-on-form grid-on-form-${theme}`} empty={<CustomEmpty/>}>
                    <DateTimeFieldTZ source="createdDate" locales="en-GB"/>
                    <FormattedAmountField
                        label="Loan Amount"
                        source="loanAmount"
                        currencyAttr="currency"
                        options={{maximumFractionDigits: 0}}
                    />
                    <EnumDictField source="statusCode" enum={LoanApplicationStatus} />
                    <TextField source="loanTerm"/>
                    <TextField source="initiatorLogin"/>
                </Datagrid>
            </ListContextProvider>
        </>
    )
}

const LegalEntityDetails = (props) => {
    const {record, label} = props;
    const [registeredOfficeCountry, setRegisteredOfficeCountry] = useState(null);
    const [primaryBusinessCountry, setPrimaryBusinessCountry] = useState(null);
    const countries = getCountries();
    const [theme] = useTheme();

    const displayAddress = (address, registeredOfficeCountry, label) => {
        return (
            <>
                {address === undefined &&
                    <Grid item xs={12}>
                        <Typography className={`form-field-label form-field-label-${theme}`}>{label}:</Typography>
                        <Typography className={`form-field-value form-field-value-${theme}`}>N/A</Typography>
                    </Grid>
                }
                {address &&
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Typography className={`grid-label-2nd grid-label-2nd-${theme}`}>{label}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography className={`form-field-label form-field-label-${theme}`}>Address line
                                    1:</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography
                                    className={`form-field-value form-field-value-${theme}`}>{orElse(address.addressLine1)}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography className={`form-field-label form-field-label-${theme}`}>Address line
                                    2:</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography
                                    className={`form-field-value form-field-value-${theme}`}>{orElse(address.addressLine2)}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography className={`form-field-label form-field-label-${theme}`}>City:</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography
                                    className={`form-field-value form-field-value-${theme}`}>{orElse(address.city)}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography
                                    className={`form-field-label form-field-label-${theme}`}>State/province:</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography
                                    className={`form-field-value form-field-value-${theme}`}>{orElse(address.stateProvince)}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography className={`form-field-label form-field-label-${theme}`}>Postal
                                    Code:</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography
                                    className={`form-field-value form-field-value-${theme}`}>{orElse(address.postalCode)}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography
                                    className={`form-field-label form-field-label-${theme}`}>Country:</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography
                                    className={`form-field-value form-field-value-${theme}`}>{getCountryByCode(registeredOfficeCountry, countries)}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </>
        )
    }

    useEffect(() => {
        let roc;
        let pbc;
        roc = record?.registeredOfficeAddress?.country;
        pbc = record?.primaryBusinessAddress?.country;
        const registeredOfficeFilteredCountry = countries.filter((country) => country[0] === record?.registeredOfficeAddress?.country);
        if (registeredOfficeFilteredCountry.length > 0) {
            roc = registeredOfficeFilteredCountry[0][1];
        }
        const primaryOfficeFilteredCountry = countries.filter((country) => country[0] === record?.primaryBusinessAddress?.country);
        if (primaryOfficeFilteredCountry.length > 0) {
            pbc = primaryOfficeFilteredCountry[0][1];
        }
        setRegisteredOfficeCountry(roc);
        setPrimaryBusinessCountry(pbc);
    }, [record]);

    if (!record) {
        return <></>
    }

    return (
        <>
            {label && <Typography className={`grid-label grid-label-${theme}`}>{label}</Typography>}
            <Grid container spacing={1} className="form-section">
                <Grid item xs={3}>
                    <Typography className={`form-field-label form-field-label-${theme}`}>Full Company Name:</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography className={`form-field-value form-field-value-${theme}`}>
                        {record.fullCompanyName}</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography className={`form-field-label form-field-label-${theme}`}>Registration
                        number:</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography className={`form-field-value form-field-value-${theme}`}>
                        {record.registrationNumber}</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography className={`form-field-label form-field-label-${theme}`}>Created Date
                        (London):</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography className={`form-field-value form-field-value-${theme}`}>
                        {toLondonDateTime(record.createdDate)}</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography className={`form-field-label form-field-label-${theme}`}>Website:</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography className={`form-field-value form-field-value-${theme}`}>
                        {orElse(record.website)}</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography className={`form-field-label form-field-label-${theme}`}>Description:</Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography className={`form-field-value form-field-value-${theme}`}>
                        {orElse(record.description)}</Typography>
                </Grid>
                {displayAddress(record.registeredOfficeAddress, registeredOfficeCountry, "Registered Office Address")}
                {displayAddress(record.primaryBusinessAddress, primaryBusinessCountry, "Primary Business Address")}
            </Grid>
        </>
    )
}

const MergeDialog = (props) => {
    const {close, sourceId, targetId, open} = props;

    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();

    const [isLoading, setIsLoading] = useState(false);
    const [sourceParty, setSourceParty] = useState({})
    const [targetParty, setTargetParty] = useState({})
    const [propsAreProcessed, setPropsAreProcessed] = useState(false)

    const handleClose = (event, reason) => {
        if (reason && (reason === "backdropClick" || reason === "escapeKeyDown"))
            return;

        close();
    }

    const onSuccessMerge = () => {
        notify(`Legal Entities were successfully merged`, {type: 'success', autoHideDuration: 2000});
        refresh();
        close();
    }

    const doMerge = (data) => {
        setIsLoading(true);

        dataProvider['doBodyAction'](Resources.LEGAL_ENTITY.name, {
            action: Resources.LEGAL_ENTITY.actions.MERGE.name,
            id: data.sourceId,
            method: 'PUT',
            body: data
        }).then(({data}) => {
            setIsLoading(false)
            if (data.success) {
                onSuccessMerge(data);
            } else {
                const msg =
                    data.errMessage
                        ? data.errMessage
                        : "System error";
                onError({message: msg}, notify)
            }
        }).catch(error => {
            setIsLoading(false)
            onError(error, notify)
        });
    }

    const validate = (form) => {
        const errors = {};
        if (!form.sourceId) {
            errors.sourceId = "ID of source Legal Entity to delete is not set";
        }
        if (!form.targetId) {
            errors.targetId = "ID of target Legal Entity is not set";
        }
        if (form.targetId === form.sourceId) {
            errors.targetId = "Target Legal Entity could not be the same as a source"
        }
        return errors;
    }

    const sourceOnChange = (id) => {
        // console.log("sourceOnChange", id)
        if (id) {
            dataProvider['doAction'](Resources.LEGAL_ENTITY.name, {
                action: Resources.LEGAL_ENTITY.actions.FULL_INFO.name,
                id: id,
                method: "GET",
            }).then(({data}) => {
                setSourceParty(data);
            }).catch((err) => {
                setSourceParty({})
                onError(err, notify)
            });
        } else {
            setSourceParty({})
        }
    }

    const targetOnChange = (id) => {
        // console.log("sourceOnChange", id)
        if (id) {
            dataProvider['doAction'](Resources.LEGAL_ENTITY.name, {
                action: Resources.LEGAL_ENTITY.actions.FULL_INFO.name,
                id: id,
                method: "GET",
            }).then(({data}) => {
                setTargetParty(data);
            }).catch((err) => {
                setTargetParty({})
                onError(err, notify)
            });
        } else {
            setTargetParty({})
        }
    }

    const displayParty = (party) => {
        return (
            <>
                <LegalEntityDetails record={party?.basicInfo} label="Basic Info"/>
                <CallReportsGrid data={party?.callReports}
                                 label="Meeting Notes"/>
                <OpportunitiesGrid data={party?.opportunitiesWhereCreditor}
                                   label="Opportunities where the company is a Debt Investor"
                                   displayBorrower/>
                <LoanApplicationsGrid data={party?.loanApplicationsWhereBorrower}
                                   label="Loan Applications where the company is a Borrower"/>
            </>
        )
    }

    const FormSetValues = (props) => {
        const {sourceId, targetId} = props;
        const {setValue} = useFormContext();
        useEffect(() => {
            if (propsAreProcessed)
                return;

            // console.log("FormSetValues", sourceId, targetId)
            if (sourceId) {
                setValue("sourceId", sourceId)
                sourceOnChange(sourceId)
            }
            if (targetId) {
                setValue("targetId", targetId)
                targetOnChange(targetId)
            }
            setPropsAreProcessed(true);
        }, [sourceId, targetId])

        return (<></>)
    }

    return (
        <Dialog onClose={handleClose} open={open} maxWidth="xl" fullWidth>
            <DialogTitle>Merge Legal Entities</DialogTitle>
            <Form onSubmit={doMerge} validate={validate} defaultValues={{sourceId, targetId}}>
                <DialogContent>
                    {!propsAreProcessed &&
                        <FormSetValues sourceId={sourceId} targetId={targetId}/>
                    }
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <Grid container spacing={12}>
                                <Grid item xs={12}>
                                    Select Source Legal Entity that will be deleted after merge
                                    <LegalEntityInput source="sourceId"
                                                      label="Source Legal Entity" fullWidth
                                                      onChange={sourceOnChange}
                                    />
                                    {displayParty(sourceParty)}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container spacing={12}>
                                <Grid item xs={12}>
                                    Select target Legal Entity
                                    <LegalEntityInput source="targetId"
                                                      label="Target Legal Entity" fullWidth
                                                      onChange={targetOnChange}
                                    />
                                    {displayParty(targetParty)}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={close} label="Cancel"/>
                    <Button type="submit" disabled={isLoading} label="Merge"/>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

MergeDialog.propTypes = {
    close: PropTypes.func.isRequired,
    sourceId: PropTypes.number,
    targetId: PropTypes.string,
    open: PropTypes.bool.isRequired,
}

export default MergeDialog;