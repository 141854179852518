import React, {useEffect, useState} from "react";
import {
    Create,
    CreateButton,
    Datagrid,
    FunctionField,
    List,
    SelectField,
    SelectInput,
    SimpleForm,
    TopToolbar,
    useDataProvider,
} from "react-admin";
import Resources from "../Resources";
import {ReportFormat, ReportNames, ReportStatus} from "./dicts/ReportsEnum";
import {LoanBookReport} from "./reports/LoanBookReport";
import DateTimeFieldTZ from "./controls/DateFieldTZ";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import CodeIcon from '@mui/icons-material/Code';
import {getResourceUrl} from '../data/MvtDataProvider'
import {ViewDialog} from "./controls/ViewDialog";
import {LoanApplicationAuditReport} from "./reports/LoanApplicationAuditReport";
import {DataroomAuditReport} from "./reports/DataroomAuditReport";
import {DataroomAuditSummaryReport} from "./reports/DataroomAuditSummaryReport";

const reportsFilters = [
    // eslint-disable-next-line react/jsx-key
    <SelectInput source="statusCode" label="Status"
                 choices={Array.from(Object.keys(ReportStatus),
                     (key) => ({id: ReportStatus[key].code, name: ReportStatus[key].label}))}
                 alwaysOn
    />
];

const dicts = {
    availableReports: [],

    loadAvailableReports: (dataProvider, setAvailableReports) => {
        useEffect(() => {
            dataProvider['doAction'](Resources.REPORTS.name, {
                action: Resources.REPORTS.actions.AVAILABLE_REPORTS.name,
                method: "GET",
            }).then(({data}) => {
                let reports = null;
                if (data.data && data.data.length) {
                    reports = data.data;
                }
                setAvailableReports(reports)
                dicts.availableReports = reports;
            }).catch(error => {
                setAvailableReports(null);
                dicts.availableReports = [];
                console.log("[availableReports] got error", error)
            });
        }, [])
    }
}

const ListActions = () => (
    <TopToolbar>
        <CreateButton/>
    </TopToolbar>
);

export const ReportList = () => {
    const dataProvider = useDataProvider();
    const [availableReports, setAvailableReports] = useState([]);
    const [paramsOpened, setParamsOpened] = useState(false);
    const [paramsContent, setParamsContent] = useState(null);

    dicts.loadAvailableReports(dataProvider, setAvailableReports)

    return (
        <List sort={{field: 'createdDate', order: 'DESC'}} filters={reportsFilters} actions={<ListActions/>} perPage={50}>
            <ViewDialog open={paramsOpened} onClose={() => setParamsOpened(false)} content={paramsContent}/>
            <Datagrid rowClick={false} bulkActionButtons={false}>
                <SelectField source="reportName" label="Report" choices={availableReports}/>
                <SelectField source="reportFormat" label="Format"
                             choices={Array.from(Object.keys(ReportFormat),
                                 (key) => ({id: ReportFormat[key].code, name: ReportFormat[key].label}))}
                />
                <DateTimeFieldTZ source="createdDate" label={"Created Date (London)"}/>
                <DateTimeFieldTZ source="expirationDate" label={"Expiration Date (London)"}/>
                <DateTimeFieldTZ source="generationDate" label={"Ready to view Date (London)"}/>
                <SelectField source="statusCode" label="Status"
                             choices={Array.from(Object.keys(ReportStatus),
                                 (key) => ({id: ReportStatus[key].code, name: ReportStatus[key].label}))}
                             alwaysOn
                />
                <FunctionField label="Params" render={(record) => {
                    const p = JSON.stringify(record.params);
                    return <span title={p}><CodeIcon fontSize="small" onClick={() => {setParamsContent(p); setParamsOpened(true)}}/></span>;
                }}/>
                <FunctionField label="Document" render={(record) => {
                    return record.document?.filename
                        ? <a target="_blank"
                             href={getResourceUrl(Resources.REPORTS.name, {id: record.id}, Resources.REPORTS.actions.DOWNLOAD.name )}
                             title={"Download " + record.document?.filename}>
                            <FileDownloadOutlinedIcon fontSize="small"/>
                          </a>
                        : ""
                }}/>
            </Datagrid>
        </List>
    );
}

export const ReportCreate = (props) => {
    const [reportName, setReportName] = useState('');
    const [availableReports, setAvailableReports] = useState([]);
    const dataProvider = useDataProvider();

    dicts.loadAvailableReports(dataProvider, setAvailableReports)

    return (
        <Create redirect="list">
            <SimpleForm>
                <SelectInput source="reportName" label="Report"
                             onChange={(event) => setReportName(event.target.value)}
                             choices={availableReports}/>
                <SelectInput source="reportFormat" label="Format"
                             choices={Array.from(Object.keys(ReportFormat),
                                 (key) => ({id: ReportFormat[key].code, name: ReportFormat[key].label}))}
                             alwaysOn
                />
                {
                    reportName === ReportNames.LoanBookReport.code
                    && <LoanBookReport/>
                }
                {
                    reportName === ReportNames.LoanApplicationAuditReport.code
                    && <LoanApplicationAuditReport/>
                }
                {
                    reportName === ReportNames.DataroomAuditReport.code
                    && <DataroomAuditReport/>
                }
                {
                    reportName === ReportNames.DataroomAuditSummaryReport.code
                    && <DataroomAuditSummaryReport/>
                }
            </SimpleForm>
        </Create>
    );
}

