import PropTypes from 'prop-types'
import React, {useState} from "react";
import DialogTitle from "@mui/material/DialogTitle";
import {
    Button,
    FileField,
    FileInput,
    Form,
    RadioButtonGroupInput,
    useDataProvider,
    useNotify,
    useRecordContext,
    useRefresh,
    useTheme,
} from "react-admin";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Grid from "@mui/material/Grid";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Resources from "../../Resources";
import {onError} from "../../common/utils";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";


export const EditNDAButton = (props) => {
    const record = useRecordContext(props);
    const {onClick, label} = props;
    return (<>
        {record?.isCreditorVerified && <Button label={label || "Edit NDA"} onClick={() => {onClick(record)}}/>}
        </>
    )
}

export default function EditNDAForLoanApplicationCreditorRelDialog(props) {
    const {close, record} = props;
    const [submitting, setSubmitting] = useState(false);
    const [creditorDocChanged, setCreditorDocChanged] = useState(false);
    const [monivoltDocChanged, setMonivoltDocChanged] = useState(false);
    const notify = useNotify();
    const refresh = useRefresh();
    const dataProvider = useDataProvider();
    const [theme] = useTheme();

    const submit = (data) => {
        setSubmitting(true);
        dataProvider['doUpload'](Resources.LOAN_APPLICATIONS.name, {
            action: Resources.LOAN_APPLICATIONS.actions.NDA.name,
            subres: Resources.LOAN_APPLICATIONS.subrequests.CREDITORS.name,
            subresId: record.creditorId,
            id: record.loanApplicationId,
            method: 'PUT',
            body: {
                ndaStatusCode: data.ndaStatusCode,
                ndaFromCreditorDoc: data.ndaFromCreditorDoc,
                ndaFromMonivoltDoc: data.ndaFromMonivoltDoc,
            },
        }).then(({data}) => {
            if (data.success) {
                onSuccess(data);
            } else {
                const msg =
                    data.errMessage
                        ? data.errMessage
                        : "System error";
                onError({message: msg}, notify)
            }
        }).catch(error => {
            onError(error, notify);
            setSubmitting(false);
        });
    };

    function onSuccess(data) {
        close();
        setSubmitting(false);
        notify(
            'NDA were updated',
            {type: 'success', autoHideDuration: 2000}
        );
        refresh();
    }

    const creditorDocOnChange = (e) => {
        setCreditorDocChanged(e != null)
    }

    const monivoltDocOnChange = (e) => {
        setMonivoltDocChanged(e != null)
    }

    const validate = (form) => {
        const errors = {};
        if (!form.ndaStatusCode) {
            errors.ndaStatusCode = "NDA status is required";
        }
        return errors;
    }

    return (
        <Dialog onClose={close} open={props.isOpened} fullWidth maxWidth="md" >
            <DialogTitle>Verify NDA for <Typography variant="h5" component="span">{record.creditor.fullCompanyName}</Typography></DialogTitle>
            <Form onSubmit={submit} record={record} validate={validate} defaultValues={{ndaFlag: "REQUIRED"}}>
                <DialogContent>
                    <DialogContentText className="note">
                        Please attach or verify signed NDA, mark "NDA is signed" flag if everything is signed by Debt Investor and Monivolt and press Submit.
                    </DialogContentText>
                    <Grid container spacing={1} className="dlg-content">
                        <Grid item xs={5}>
                            <Typography className={`form-field-label form-field-label-${theme}`}>NDA from Debt Investor</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            {record.ndaFromCreditorDoc
                                && <Link
                                        href={`/api/v1/loanApplications/${record.loanApplicationId}/creditors/${record.creditorId}/nda/${record.ndaFromCreditorDocId}`}>
                                        {record.ndaFromCreditorDoc.filename}
                                   </Link>}
                            {!record.ndaFromCreditorDoc
                                    && "-"
                            }
                        </Grid>
                        <Grid item xs={5}>
                            <Typography className={`form-field-label form-field-label-${theme}`}>Upload new file if you want to change NDA from Debt Investor</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <FileInput multiple={false} source="ndaFromCreditorDoc" onChange={creditorDocOnChange}>
                                {creditorDocChanged && <FileField source="src" title="title" target="_blank" />}
                            </FileInput>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography className={`form-field-label form-field-label-${theme}`}>NDA from Monivolt</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            {record.ndaFromMonivoltDoc
                                && <Link
                                        href={`/api/v1/loanApplications/${record.loanApplicationId}/creditors/${record.creditorId}/nda/${record.ndaFromMonivoltDocId}`}>
                                        {record.ndaFromMonivoltDoc.filename}
                                   </Link>}
                            {!record.ndaFromMonivoltDoc
                                    && "-"
                            }
                        </Grid>
                        <Grid item xs={5}>
                            <Typography className={`form-field-label form-field-label-${theme}`}>Upload new file if you want to change NDA from Monivolt</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <FileInput multiple={false} source="ndaFromMonivoltDoc" onChange={monivoltDocOnChange}>
                                {monivoltDocChanged && <FileField source="src" title="title" target="_blank" />}
                            </FileInput>
                        </Grid>
                        <RadioButtonGroupInput source="ndaStatusCode" label="NDA Status"
                                               className="nda-rg"
                                               choices={[
                            { id: 'SIGNED', name: 'NDA is signed' },
                            { id: 'REQUIRED', name: 'NDA is required' },
                            { id: 'NOT_REQUIRED', name: 'NDA is not required' },
                        ]} />
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={close} label="Cancel"/>
                    <Button type="submit" disabled={submitting} label="Submit"/>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

EditNDAForLoanApplicationCreditorRelDialog.propTypes = {
  close: PropTypes.func.isRequired,
  isOpened: PropTypes.bool.isRequired,
  record: PropTypes.object.isRequired
}
