import PropTypes from 'prop-types'
import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {
    TextInput,
    Button,
    Form,
    useDataProvider,
    useRefresh,
    useNotify,
    useGetList,
    useList,
    Datagrid, FunctionField, ListContextProvider
} from "react-admin";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Grid from "@mui/material/Grid";
import DialogActions from "@mui/material/DialogActions";
import {useState} from "react";
import Resources from "../../Resources";
import {onError} from "../../common/utils";
import Checkbox from "@mui/material/Checkbox";
import {PartyField} from "../controls/PartyField";
import {PARTY_TYPE} from "../../common/constants";

export const MANAGE_FOLDER_ACTION = {
    CREATE: "create",
    UPDATE: "update"
}

function generateDialogLabel(action, setId, selectedFolderId, selectedFolderName) {
    const actionLabel = action ? action.substring(0,1).toUpperCase() + action.substring(1) : "";
    let contextFolder = "";
    if(action === MANAGE_FOLDER_ACTION.CREATE) {
        contextFolder = " a folder under ";
        if(selectedFolderId) {
            contextFolder += selectedFolderName + " folder";
        } else {
            contextFolder += setId + " set";
        }
    } else if(action === MANAGE_FOLDER_ACTION.UPDATE) {
        contextFolder = " " + selectedFolderName;
    }
    return actionLabel + contextFolder;
}

function DataRoomManageFolderDialog(props) {
    const {close, loanApplicationId, setId, selectedFolderId, selectedFolderName, action, isProtected, acceptedCreditors} = props;
    const [submitting, setSubmitting] = useState(false);
    const refresh = useRefresh();
    const dataProvider = useDataProvider();
    const notify = useNotify();

    const method = action === MANAGE_FOLDER_ACTION.UPDATE ? 'PUT' : 'POST';
    const submitAction = action === MANAGE_FOLDER_ACTION.UPDATE ? `dataRoom/sets/${setId}/folders/${selectedFolderId}` : `dataRoom/sets/${setId}/folders`;
    const formRecord = action === MANAGE_FOLDER_ACTION.UPDATE ? {name: selectedFolderName, allowedCreditorId: acceptedCreditors} : null;
    const bodyData = action === MANAGE_FOLDER_ACTION.CREATE ? {parentId: selectedFolderId} : {};
    const actionLabel = generateDialogLabel(action, setId, selectedFolderId, selectedFolderName);
    const [allowedCreditorId, setAllowedCreditorId] = useState(acceptedCreditors !== undefined ? acceptedCreditors : []);

    const validateForm = (form) => {
        const errors = {};
        if(!form.name) {
            errors.name = "Name is required";
        }
        return errors;
    }

    let creditors = useGetList(Resources.LOAN_APPLICATIONS.name + "/" + loanApplicationId
        + "/" + Resources.LOAN_APPLICATIONS.subrequests.CREDITOR_RELS.name,
        {
            pagination: {page: 0, perPage: 100},
        });
    let creditorsListContext = useList(creditors);

    const submit = ({name}) => {
        setSubmitting(true);
        dataProvider['doBodyAction'](Resources.LOAN_APPLICATIONS.name, {
            action: submitAction,
            id: loanApplicationId,
            method: method,
            body: {name, allowedCreditorId, ...bodyData},
        }).then(({data}) => {
            if (data.success) {
                onSuccess(data);
            } else {
                const msg =
                    data.errMessage
                        ? data.errMessage
                        : "System error";
                onError({message: msg}, notify)
            }
        }).catch(error => {
            onError(error, notify);
            setSubmitting(false);
        });
    };

    function onSuccess(data) {
        close();
        setSubmitting(false);
        refresh();
    };

    function onCreditorClick(relId, resource, record) {
        const creditorId = record.creditorId
        if(allowedCreditorId.includes(creditorId)) {
            setAllowedCreditorId([...allowedCreditorId.filter((val) => val !== creditorId)])
        } else {
            setAllowedCreditorId([...allowedCreditorId, creditorId]);
        }
        return false;
    }

    return (
        <>
            <Dialog onClose={close} open={props.isOpened} fullWidth maxWidth="sm">
                <DialogTitle>{actionLabel}</DialogTitle>
                <Form onSubmit={submit} validate={validateForm} record={formRecord}>
                    <DialogContent>
                        <DialogContentText>
                            Please set a folder name
                        </DialogContentText>
                        <Grid container spacing={1}>
                            <Grid item xs={11}>
                                <TextInput isRequired source="name" />
                            </Grid>
                            {isProtected &&
                                <Grid item xs={11}>
                                    <ListContextProvider value={creditorsListContext}>
                                        <Datagrid bulkActionButtons={false} rowClick={onCreditorClick}>
                                            <FunctionField label=""
                                                           render={(record) => {
                                                               return (
                                                                   <Checkbox source="acl" size="small"
                                                                             checked={allowedCreditorId.includes(record.creditorId)}/>
                                                               );
                                                           }}/>
                                            <PartyField source="creditorId" partyType={PARTY_TYPE.LEGAL_ENTITY}
                                                        label="Debt Investor"/>
                                        </Datagrid>
                                    </ListContextProvider>
                                </Grid>
                            }
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={close} label="Cancel"/>
                        <Button type="submit" disabled={submitting} label="Submit"/>
                    </DialogActions>
                </Form>
            </Dialog>
        </>
    );
}

export default DataRoomManageFolderDialog;

DataRoomManageFolderDialog.propTypes = {
  action: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
  isOpened: PropTypes.bool.isRequired,
  loanApplicationId: PropTypes.number.isRequired,
  selectedFolderId: PropTypes.string,
  selectedFolderName: PropTypes.string,
  setId: PropTypes.string.isRequired
}