import PropTypes from 'prop-types'
import {ListContextProvider, Tab, TabbedShowLayout, useGetList, useGetOne, useList} from "react-admin";
import Resources from "../../Resources";
import PersonsDatagrid from "../persons/PersonsDatagrid";
import * as React from "react";
import LoanApplicationDetails from "./LoanApplicationDetails";
import LegalEntityDetails from "./LegalEntityDetails";
import LoanApplicationDocuments from "./LoanApplicationDocuments";
import LoanApplicationCreditorRelList from "../loan_applications/LoanApplicationCreditorRelList";
import DataroomAuditList from "../loan_applications/DataroomAuditList";
import {PRODUCT_APPLICATION_TYPES} from "../dicts/PartyEnums";
import CallReportListPage from "../callReports/CallReportListPage";
import {PARTY_TYPE} from "../../common/constants";
import LoanApplicationTermSheet from "../loan_applications/LoanApplicationTermSheet";

function LoanApplicationForLegalEntities(props) {

    const {record} = props;

    const {id, partyId} = record || {};

    let legalEntityHook = useGetOne(Resources.LEGAL_ENTITY.name, {id: record.partyId});

    let personsHook = useGetList(
        Resources.LEGAL_ENTITY.name + "/" + record.partyId + "/" + Resources.PERSONS.name, {sort: {field: "id", order: "DESC"}}
    );
    let personsListContext = useList(personsHook);
    let partyDict = {};
    // let registeredOfficeCountry;
    // let primaryBusinessCountry;
    // const countries = useCountries();
    if (legalEntityHook.data) {
        const legalEntity = legalEntityHook.data;
        // console.log(legalEntity)
        partyDict[legalEntity.id] = {
            id: legalEntity.id,
            name: legalEntity.fullCompanyName,
            type: Resources.LEGAL_ENTITY.name
        }


    }
    if (personsHook.data) {
        for (const person of personsHook.data) {
            partyDict[person.id] = {
                id: person.id,
                name: person.firstName + " " + person.lastName,
                type: Resources.PERSONS.name
            }
        }
    }
    // console.log("personsHook.data", personsHoregisteredOfficeCountryok.data)
    let personIds = personsHook.data?.map(person => person.id);

    return (
        <TabbedShowLayout>
            <Tab label="Loan info">
                <LoanApplicationDetails record={record}/>
            </Tab>
            <Tab label="Legal Entity">
                {/*<RecordContextProvider value={legalEntityHook?.data}>*/}
                <LegalEntityDetails record={legalEntityHook?.data} />
            </Tab>
            <Tab label="Connected Persons">
                {personsHook ? (
                    <ListContextProvider value={personsListContext}>
                        <PersonsDatagrid withRelations={true}/>
                    </ListContextProvider>
                ) : ""}
            </Tab>
            <Tab label="Documents">
                {/*<DocumentsDataList partyId={record.partyId} partyIds={personIds} partyDict={partyDict}/>*/}
                <LoanApplicationDocuments loanApplication={record}/>
            </Tab>
            <Tab label="Debt Investors">
                <LoanApplicationCreditorRelList loanApplication={record} />
            </Tab>
            <Tab label={"Term sheet"}>
                <LoanApplicationTermSheet loanApplication={record} />
            </Tab>
            <Tab label="Dataroom Audit">
                <DataroomAuditList loanApplication={record} />
            </Tab>
            <Tab label="Meeting Notes">
                {id && partyId &&
                    <CallReportListPage resource={Resources.CALL_REPORT.name}
                                        filter={{orInitiatorCompany: partyId,
                                            orApplicationType: PRODUCT_APPLICATION_TYPES.LOAN_APPLICATION.code,
                                            orApplicationId: id
                                        }}
                                        hideFilters
                                        defaultCallReportData={{productApplications:[{
                                                applicationType: PRODUCT_APPLICATION_TYPES.LOAN_APPLICATION.code,
                                                applicationId: id,
                                                partyType: PARTY_TYPE.LEGAL_ENTITY,
                                                partyId: partyId
                                            }]}}
                    />
                }
            </Tab>
        </TabbedShowLayout>

    );
}

export default LoanApplicationForLegalEntities;

LoanApplicationForLegalEntities.propTypes = {
  record: PropTypes.object.isRequired,
}