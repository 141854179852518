import PropTypes from 'prop-types'
import {Tab, TabbedShowLayout, useGetOne} from "react-admin";
import Resources from "../../Resources";
import * as React from "react";
import DocumentsDataList from "./DocumentsDataList";
import LoanApplicationDetails from "./LoanApplicationDetails";
import PersonDetails from "./PersonDetails";
import LoanApplicationDocuments from "./LoanApplicationDocuments";
import LoanApplicationCreditorRelList from "../loan_applications/LoanApplicationCreditorRelList";

function LoanApplicationForHNW(props) {

    const {record} = props;
    let personHook = useGetOne(Resources.PERSONS.name, {id: record.partyId});
    // console.log(personHook.data)

    // let personsHook = useGetList(Resources.LEGAL_ENTITY.name + "/" + record.partyId + "/" + Resources.PERSONS.name);
    // let personsListContext = useList(personsHook);
    // console.log("personsHook", personsHook.data)
    let partyDict = {};
    if (personHook.data) {
        const person = personHook.data;
        // console.log(legalEntity)
        partyDict[person.id] = {
            id: person.id,
            name: person.firstName + " " + person.lastName,
            type: Resources.PERSONS.name
        }
        personHook.data.loanApplication = record.rawdata;

    }
    let personIds = personHook.data?.id;

    return (
        <TabbedShowLayout>
            <Tab label="Loan info">
                <LoanApplicationDetails record={record}/>
            </Tab>
            <Tab label="Person">
                <PersonDetails record={personHook.data} />
            </Tab>

            <Tab label="Documents">
                <DocumentsDataList partyId={record.partyId} partyIds={personIds} partyDict={partyDict}/>
                <LoanApplicationDocuments loanApplication={record}/>
            </Tab>
            <Tab label="Debt Investors">
                <LoanApplicationCreditorRelList loanApplication={record} />
            </Tab>

        </TabbedShowLayout>

    );
}

export default LoanApplicationForHNW;

LoanApplicationForHNW.propTypes = {
  record: PropTypes.object.isRequired,
}