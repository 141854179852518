import PropTypes from 'prop-types'
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {
    Button,
    Datagrid,
    Form,
    FunctionField,
    ListContextProvider,
    useDataProvider, useGetList, useList,
    useNotify,
    useRefresh,
} from "react-admin";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Grid from "@mui/material/Grid";
import DialogActions from "@mui/material/DialogActions";
import React, {useEffect, useState} from "react";
import Resources from "../../Resources";
import {onError} from "../../common/utils";
import LoanApplicationTitle from "../controls/LoanApplicationTitle";
import Checkbox from "@mui/material/Checkbox";
import {PartyField} from "../controls/PartyField";
import {PARTY_TYPE} from "../../common/constants";

function FundLoanApplicationDialog(props) {
    const {close} = props;
    const [fundingCreditorId, setFundingCreditorId] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const {loanApplication} = props;
    const loanApplicationId = loanApplication.id;
    const notify = useNotify();
    const refresh = useRefresh();
    const dataProvider = useDataProvider();

    let creditors = useGetList(Resources.LOAN_APPLICATIONS.name + "/" + loanApplicationId
        + "/" + Resources.LOAN_APPLICATIONS.subrequests.CREDITOR_RELS.name,
        {
            pagination: {page: 0, perPage: 100},
        });
    let creditorsListContext = useList(creditors);

    const validateForm = (form) => {
        const errors = {};
        if(fundingCreditorId.length === 0) {
            errors.fundingCreditorId = "Choose a funding Debt Investor";
        }
        return errors;
    }

    const submit = (data) => {
        setSubmitting(true);
        dataProvider['doBodyAction'](Resources.LOAN_APPLICATIONS.name, {
            action: Resources.LOAN_APPLICATIONS.actions.FUND.name,
            id: loanApplicationId,
            method: 'PUT',
            body: fundingCreditorId,
        }).then(({data}) => {
            if (data.success) {
                onSuccess(data);
            } else {
                const msg =
                    data.errMessage
                        ? data.errMessage
                        : "System error";
                onError({message: msg}, notify)
            }
        }).catch(error => {
            onError(error, notify);
            setSubmitting(false);
        });
    };

    function onSuccess(data) {
        close();
        setSubmitting(false);
        notify(
            'Loan application was funded successfully',
            {type: 'success', autoHideDuration: 2000}
        );
        refresh();
    }

    function onCreditorClick(relId, resource, record) {
        const creditorId = record.creditorId
        if(fundingCreditorId.includes(creditorId)) {
            setFundingCreditorId([...fundingCreditorId.filter((val) => val !== creditorId)])
        } else {
            setFundingCreditorId([...fundingCreditorId, creditorId]);
        }
        return false;
    }

    return (
        <Dialog onClose={close} open={props.isOpened} fullWidth maxWidth="md">
            <DialogTitle>Fund Loan Application (<LoanApplicationTitle  loanApplication={loanApplication} />)</DialogTitle>
            <Form onSubmit={submit} validate={validateForm}>
                <DialogContent>
                    <DialogContentText>
                        Please choose a Debt Investors who confirmed to fund the loan application.
                    </DialogContentText>
                    <Grid container spacing={1}>
                        <Grid item xs={11}>
                            <ListContextProvider value={creditorsListContext}>
                                <Datagrid bulkActionButtons={false} rowClick={onCreditorClick}>
                                    <FunctionField label=""
                                                   render={(record) => {
                                        return (
                                            <Checkbox source="fundingCreditorId" size="small" checked={fundingCreditorId.includes(record.creditorId)}/>
                                        );
                                    }} />
                                    <PartyField source="creditorId" partyType={PARTY_TYPE.LEGAL_ENTITY} label="Debt Investor" />
                                </Datagrid>
                            </ListContextProvider>

{/*
                            <AutocompleteInput isRequired choices={loanApplicationCreditors} source="fundingCreditorId"
                                               optionText="creditor.fullCompanyName" optionValue="creditorId" name="fundingCreditorId"/>
*/}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={close} label="Cancel"/>
                    <Button type="submit" disabled={submitting} label="Submit"/>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

FundLoanApplicationDialog.propTypes = {
  close: PropTypes.func.isRequired,
  isOpened: PropTypes.bool.isRequired,
  loanApplication: PropTypes.object.isRequired
}

export default FundLoanApplicationDialog;