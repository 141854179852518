import * as React from "react";
import {
    BooleanField,
    Datagrid,
    Edit,
    EditButton,
    List,
    SelectInput,
    SimpleForm, SimpleShowLayout,
    TextField,
    TextInput,
    BooleanInput,
} from 'react-admin';
import {PartyInput} from "../controls/PartyInput";
import {PartyRelations} from "../dicts/PartyPartyRelEnums";
import PartyPartyRelDatagrid from "./PartyPartyRelDatagrid";
import EnumDictInput from "../controls/EnumDictInput";

const relationFilters = [
    <EnumDictInput enum={PartyRelations} source="relationType" label="Relation Type"
                 alwaysOn
    />,
    <PartyInput source="fromId" alwaysOn label="Party From" />,
    <PartyInput source="toId" alwaysOn label="Party To"/>,
];
function PartyPartyRelListPage(props) {
    return (
        <List filters={relationFilters} sort={{field: "id", order: "DESC"}} perPage={50}>
            <PartyPartyRelDatagrid showBothEntities />
        </List>
    );
}

export default PartyPartyRelListPage;