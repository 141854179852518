import PropTypes from 'prop-types'
import React, {useState} from "react";
import DialogTitle from "@mui/material/DialogTitle";
import {
    ArrayInput,
    Button,
    Form,
    SimpleFormIterator,
    useDataProvider,
    useNotify,
    useRefresh,
} from "react-admin";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Grid from "@mui/material/Grid";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Resources from "../../Resources";
import {onError} from "../../common/utils";
import LegalEntityInput from "../controls/LegalEntityInput";
import LoanApplicationTitle from "../controls/LoanApplicationTitle";

function AppendNonVerifiedCreditorsDialog(props) {
    const {close} = props;
    const [submitting, setSubmitting] = useState(false);
    const {loanApplication} = props;
    const notify = useNotify();
    const refresh = useRefresh();
    const dataProvider = useDataProvider();

    const validateForm = (form) => {
        const errors = {};
        if(form.nonVerifiedCreditorIds.length === 0) {
            errors.nonVerifiedCreditorIds = "At least one Debt Investor has to be added";
        }
        return errors;
    }

    const submit = (data) => {
        setSubmitting(true);
        // console.log('onCapitalSourcingSubmit', data)
        dataProvider['doUpload'](Resources.LOAN_APPLICATIONS.name, {
            action: Resources.LOAN_APPLICATIONS.actions.APPEND_CREDITORS.name,
            id: loanApplication.id,
            method: 'PUT',
            body: {nonVerifiedCreditorIds: data.nonVerifiedCreditorIds.map(e => e.partyId).toString()},
        }).then(({data}) => {
            if (data.success) {
                onSuccess(data);
            } else {
                const msg =
                    data.errMessage
                        ? data.errMessage
                        : "System error";
                onError({message: msg}, notify)
            }
        }).catch(error => {
            onError(error, notify);
            setSubmitting(false);
        });
    };

    function onSuccess(data) {
        close();
        setSubmitting(false);
        notify(
            'Now borrower can see new non-verified Debt Investors',
            {type: 'success', autoHideDuration: 2000}
        );
        refresh();
    }

    return (
        <Dialog onClose={close} open={props.isOpened} fullWidth maxWidth="md">
            <DialogTitle>Append Non-Verified Debt Investors (<LoanApplicationTitle  loanApplication={props.loanApplication} />)</DialogTitle>
            <Form onSubmit={submit} validate={validateForm}>
                <DialogContent>
                    <DialogContentText>
                        Please add non-verified Debt Investors and press Submit.
                    </DialogContentText>
                    <Grid container spacing={1}>
                        <Grid item xs={11}>
                            <ArrayInput source="nonVerifiedCreditorIds" fullWidth label="">
                                <SimpleFormIterator fullWidth inline disableReordering>
                                    <LegalEntityInput
                                        source="partyId" label="Organisation" fullWidth
                                        resource={Resources.CREDITORS.name + "/"
                                            + Resources.CREDITORS.actions.FIND_NON_VERIFIED_BY_NAME.name}
                                    />
                                </SimpleFormIterator>
                            </ArrayInput>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={close} label="Cancel"/>
                    <Button type="submit" disabled={submitting} label="Submit"/>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

export default AppendNonVerifiedCreditorsDialog;

AppendNonVerifiedCreditorsDialog.propTypes = {
  close: PropTypes.func.isRequired,
  isOpened: PropTypes.bool.isRequired,
  loanApplication: PropTypes.object.isRequired
}
