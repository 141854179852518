import PropTypes from 'prop-types'
import React, {useEffect, useState} from "react";
import DialogTitle from "@mui/material/DialogTitle";
import {Button, Form, NumberInput, TextInput, useDataProvider, useNotify, useRefresh} from "react-admin";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Grid from "@mui/material/Grid";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Resources from "../../Resources";
import {onError} from "../../common/utils";
import DictArrayInput from "../controls/DictArrayInput";
import CountryInput from "../controls/CountryInput";
import {CurrencyInput} from "../controls/CurrencyInput";
import {CurrencyType} from "../dicts/ExchangeEnums";
import {FormattedNumberInput} from "../controls/FormattedNumberInput";

function CreditorFundingCriteriasDialog(props) {
    const {close} = props;
    const [submitting, setSubmitting] = useState(false);

    const [fundingCriterias, setFundingCriterias] = useState();

    const notify = useNotify();
    const refresh = useRefresh();
    const dataProvider = useDataProvider();


    useEffect(() => {
        dataProvider.doAction(Resources.CREDITORS.name + "/" + props.creditorId
            + "/" + Resources.CREDITORS.subrequests.FUNDING_CRITERIAS.name, {method: "GET"})
            .then(({data}) => {
                setFundingCriterias(data);
            }).catch((error) => {
            onError(error, notify);
        })
    }, [])

    const validateForm = (form) => {
        const errors = {};
        if(!form.currency) {
            errors.currency = "Currency required";
        }
        if (form.loanAmountMin && form.loanAmountMax && form.loanAmountMin > form.loanAmountMax) {
            errors.loanAmountMin = "The \"Loan Amount Min\" cannot be bigger than the \"Loan Amount Max\""
        }
        return errors;
    }

    function onSuccess() {
        close();
        setSubmitting(false);
        notify(
            'Investment criteria are updated.',
            {type: 'success', autoHideDuration: 2000}
        );
        refresh();
    }


    const submit = (data) => {
        setSubmitting(true);
        // console.log('onCapitalSourcingSubmit', data)
        dataProvider.update(Resources.CREDITORS.name, {
            data: data,
            meta: Resources.CREDITORS.subrequests.FUNDING_CRITERIAS.name,
            id: props.creditorId,
        }).then((data) => {
            if (data) {
                onSuccess();
            } else {
                const msg =
                    data.errMessage
                        ? data.errMessage
                        : "System error";
                onError({message: msg}, notify)
            }
        }).catch(error => {
            onError(error, notify);
            setSubmitting(false);
        });
    }

    return (
        <Dialog onClose={close} open={props.isOpened} fullWidth maxWidth="md" PaperProps={{
            sx: {
                maxHeight: 750
            }
        }}>
            <DialogTitle>Investment Criteria - {props.creditorName}</DialogTitle>
            <Form record={fundingCriterias} onSubmit={submit} validate={validateForm}>
                <DialogContent>
                    <DialogContentText>
                        Enter investment criteria
                    </DialogContentText>
                    <Grid container spacing={1} className="big-form-grid">
                        <Grid item xs={4}>
                            <CurrencyInput source="currency" required={true} currencyTypeCode={CurrencyType.FIAT.code} label="Currency *" fullWidth />
                        </Grid>
                        <Grid item xs={4}>
                            <FormattedNumberInput source="loanAmountMin" label="Loan Amount Min" fullWidth/>
                        </Grid>
                        <Grid item xs={4}>
                            <FormattedNumberInput source="loanAmountMax" label="Loan Amount Max" fullWidth/>
                        </Grid>
                        <Grid item xs={4}>
                            <FormattedNumberInput source="revenueMin" label="Revenue Min" fullWidth/>
                        </Grid>
                        <Grid item xs={4}>
                            <FormattedNumberInput source="revenueMax" label="Revenue Max" fullWidth/>
                        </Grid>
                        <Grid item xs={4}>
                            <FormattedNumberInput source="ebitdaMin" label="EBITDA Min" fullWidth/>
                        </Grid>
                        <Grid item xs={4}>
                            <FormattedNumberInput source="ebitdaMax" label="EBITDA Max" fullWidth/>
                        </Grid>
                        <Grid item xs={4}>
                            <FormattedNumberInput source="enterpriseValueMin" label="Enterprise Value Min" fullWidth/>
                        </Grid>
                        <Grid item xs={4}>
                            <FormattedNumberInput source="enterpriseValueMax" label="Enterprise Value Max" fullWidth/>
                        </Grid>
                        <Grid item xs={4}>
                            <FormattedNumberInput source="loanAmountSweetSpotMin" label="Loan Amount Sweet Spot Min" fullWidth/>
                        </Grid>
                        <Grid item xs={4}>
                            <FormattedNumberInput source="loanAmountSweetSpotMax" label="Loan Amount Sweet Spot Max" fullWidth/>
                        </Grid>
                        <Grid item xs={4}>
                            <NumberInput source="termMin" label="Loan Term (in months) Min" fullWidth/>
                        </Grid>
                        <Grid item xs={4}>
                            <NumberInput source="termMax" label="Loan Term (in months) Max" fullWidth/>
                        </Grid>
                        <Grid item xs={4}>
                            <NumberInput source="irrMin" label="Returns From, IRR, %" fullWidth/>
                        </Grid>
                        <Grid item xs={4}>
                            <NumberInput source="irmmMin" label="Returns From, Money Multiple" fullWidth/>
                        </Grid>
                        <Grid item xs={4}>
                            <DictArrayInput label="Borrower Types" source="borrowerTypes" reference={Resources.DICT_BORROWER_TYPES.name} fullWidth/>
                        </Grid>
                        <Grid item xs={4}>
                            <DictArrayInput label="Business Stages" source="businessStages" reference={Resources.DICT_BUSINESS_STAGES.name} fullWidth/>
                        </Grid>
                        <Grid item xs={4}>
                            <DictArrayInput label="Loan Types" source="loanTypes"
                                            reference={Resources.DICT_LOAN_TYPES.name} fullWidth/>
                        </Grid>
                        <Grid item xs={4}>
                            <DictArrayInput label="Use of Funds" source="useOfFunds"
                                            reference={Resources.DICT_USE_OF_FUNDS.name} fullWidth/>
                        </Grid>
                        <Grid item xs={4}>
                            <DictArrayInput label="Loan Structures" source="loanStructures"
                                            reference={Resources.DICT_LOAN_STRUCTURES.name} fullWidth/>
                        </Grid>
                        <Grid item xs={4}>
                            <DictArrayInput label="Sectors" source="sectors"
                                       reference={Resources.DICT_SECTORS.name} fullWidth/>
                        </Grid>
                        <Grid item xs={4}>
                            <DictArrayInput label="Regions" source="regions"
                                       reference={Resources.DICT_REGIONS.name} fullWidth
                                            sort={{field: "sortOrder", order: "ASC"}}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <CountryInput source="noGoCountries" label="NO-GO Countries" multipleChoice={true} fullWidth />
                        </Grid>
                        <Grid item xs={12}>
                            <TextInput source="comments" label="Comments" fullWidth />
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={close} label="Cancel"/>
                    <Button type="submit" disabled={submitting} label="Submit"/>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

export default CreditorFundingCriteriasDialog;

CreditorFundingCriteriasDialog.propTypes = {
  close: PropTypes.func.isRequired,
  creditorId: PropTypes.number.isRequired,
  creditorName: PropTypes.string.isRequired,
  isOpened: PropTypes.bool.isRequired
}