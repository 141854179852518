import * as React from "react";
import {
    ArrayInput,
    AutocompleteInput,
    BooleanField,
    BooleanInput,
    Create,
    Datagrid,
    Edit,
    EditButton,
    FormDataConsumer,
    List,
    NumberField,
    NumberInput, ReferenceField,
    ReferenceInput, required,
    SimpleForm,
    SimpleFormIterator,
    TextField,
    TextInput,
} from "react-admin";
import {EditToolbar} from "./Dict";
import {CurrencyTypeSelectInput} from "../controls/CurrencyTypeSelectInput";
import {AdminPortalRoles} from "./Security";
import {CheckPermission} from "../security/CheckPermission";
import {Typography} from "@mui/material";
import Grid from "@mui/material/Grid";
import {CurrencyInput} from "../controls/CurrencyInput";
import {useWatch} from "react-hook-form";
import {CurrencyType} from "./ExchangeEnums";
import Resources from "../../Resources";

export const CurrencyDictList = (props) => (
    <List {...props} /*actions={<ListActions/>}*/ sort={{field: "sortOrder", order: "ASC"}} perPage={50}>
        <Datagrid rowClick={false} bulkActionButtons={false}>
            <TextField source="currencyType"/>
            <TextField source="code"/>
            <TextField source="name"/>
            <TextField source="description"/>
            <NumberField source="scale"/>
            <BooleanField source="isForLoan"/>
            <BooleanField source="isForCollateral"/>
            <TextField source="tradeCurrencyCode"/>
            <ReferenceField label="Source API" source="sourceApiCode" reference={Resources.EXT_API.name}>
                <TextField source="name"/>
            </ReferenceField>
            <NumberField source="sortOrder"/>
            <CheckPermission role={AdminPortalRoles.BACKOFFICE_ADMIN}>
                <EditButton/>
            </CheckPermission>
        </Datagrid>
    </List>
);

const EditableForm = () => {
    const currencyType = useWatch({name: 'currencyType'});
    const sourceApiCode = useWatch({name: 'sourceApiCode'});
    return (
        <>
        <Grid container spacing={1}>
            <Grid item xs={2}>
                <CurrencyTypeSelectInput source="currencyType" fullWidth validate={required()}/>
            </Grid>
            <Grid item xs={2}>
                <TextInput source="code" name="code" required fullWidth/>
            </Grid>
            <Grid item xs={2}>
                <TextInput source="name" name="name" required fullWidth/>
            </Grid>
            <Grid item xs={2}>
                <NumberInput source="sortOrder" name="sortOrder" fullWidth/>
            </Grid>
            <Grid item xs={4}>
                <TextInput source="description" name="description" fullWidth/>
            </Grid>
            <Grid item xs={2}>
                <BooleanInput source="isForLoan" fullWidth/>
            </Grid>
            <Grid item xs={2}>
                <BooleanInput source="isForCollateral" fullWidth/>
            </Grid>
            {(currencyType === CurrencyType.EQUITY.code
                    || currencyType === CurrencyType.BOND.code
                    || currencyType === CurrencyType.PRIVATE_ASSETS.code) &&
                <Grid item xs={2}>
                    <CurrencyInput
                        source="tradeCurrencyCode"
                        label="Currency to trade in"
                        currencyTypeCode="FIAT"
                        fullWidth
                        validate={required()}
                    />
                </Grid>
            }
            <Grid item xs={2}>
                <NumberInput source="scale" name="scale" fullWidth/>
            </Grid>
            <Grid item xs={2}>
                <Typography fontSize="12px">
                    Scale is a number of decimal digits for amounts in the current currency.
                    Will be used for roundings to store in database, calculations and display to user.
                </Typography>
            </Grid>
        </Grid>
        <Grid container spacing={1}>
            <Grid item xs={2}>
                <ReferenceInput source="sourceApiCode" label="Ext API" reference={Resources.EXT_API.name} sort={{field:'code', order: 'ASC'}}>
                    <AutocompleteInput optionText="name" optionValue="code"/>
                </ReferenceInput>
            </Grid>
            {
                sourceApiCode === 'MANUAL' &&
                <Grid item xs={2}>
                    <Typography fontSize="12px">
                        Use "Manually managed" when it's planned to add spot rates for the currency manually using BackOffice Console.
                    </Typography>
                </Grid>
            }
            {
                sourceApiCode === 'IBKR' &&
                <Grid item xs={12}>
                    <ArrayInput label="Interactive Brokers details" source="payload.ibkr">
                        <SimpleFormIterator inline disableReordering>
                            <CurrencyInput source="currencyTo" doNotPrintCurrencyType={true}
                                           currencyTypeCode={[CurrencyType.FIAT.code]} validate={required()} />
                            <NumberInput label="Contract ID" source="ibkrId" validate={required()}/>
                            <TextInput source="description"/>
                        </SimpleFormIterator>
                    </ArrayInput>
                </Grid>
            }
        </Grid>
        </>
    );
}

export const CurrencyDictEdit = (props) => {
    return (
        <Edit {...props}>
            <SimpleForm toolbar={<EditToolbar/>} mode="onBlur" reValidateMode="onBlur">
                <FormDataConsumer>
                    {({formData, ...rest}) => (
                        <EditableForm rest/>
                    )}
                </FormDataConsumer>
            </SimpleForm>
        </Edit>
    );
}

export const CurrencyDictCreate = (props) => (
    <Create {...props}>
        <SimpleForm mode="onBlur" reValidateMode="onBlur">
            <FormDataConsumer>
                {({formData, ...rest}) => (
                    <EditableForm rest/>
                )}
            </FormDataConsumer>
        </SimpleForm>
    </Create>
);
