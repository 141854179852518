import * as React from "react";
import {useMemo, useState} from "react";
import Grid from "@mui/material/Grid";
import {DateInput, Form, SaveButton} from "react-admin";
import {CurrencyInput} from "./view/controls/CurrencyInput";
import {LoanApplicationsByStatus} from "./view/dashboard/LoanApplicationsByStatus";
import {LoanApplicationsByLoanType} from "./view/dashboard/LoanApplicationsByLoanType";

const FilterForm = (props) => {
    return (
        <Grid container columnSpacing={1} rowSpacing={1}>
            <Grid item xs={3}>
                <CurrencyInput source="currency" required/>
            </Grid>
            <Grid item xs={3}>
                <DateInput label="Created Date (from)" source="createdDateFrom" required/>
            </Grid>
            <Grid item xs={3}>
                <DateInput label="Created Date (to)" source="createdDateTo" required/>
            </Grid>
            <Grid item xs={1}>
                <SaveButton color="primary" size="large" variant="contained" sx={{marginTop: "10px"}} label="Apply"/>
            </Grid>
        </Grid>
    )
}

export const Dashboard = () => {
    const getDateFrom = () => {
        let d = new Date()
        d.setMonth(d.getMonth() - 1)
        return d
    }
    const defaultValues = useMemo(
        () => ({currency: "USD", createdDateFrom: getDateFrom(), createdDateTo: new Date()}),
        []
    )
    const [filterValues, setFilterValues] = useState(defaultValues)
    const onSubmit = (data) => {
        console.log("filterValues", data)
        setFilterValues(data)
    }
    const validate = (form) => {
        const errors = {};
        if (!form.currency) {
            errors.currency = "Currency is required";
        }
        if (!form.createdDateFrom) {
            errors.createdDateFrom = "Created Date (From) is required";
        }
        if (!form.createdDateTo) {
            errors.createdDateTo = "Created Date (To) is required";
        }
        return errors;
    }
    return (
        <Form defaultValues={defaultValues} onSubmit={onSubmit} validate={validate}>
            <Grid container columnSpacing={1} rowSpacing={1} className="dashboard-grid">
                <Grid item xs={11}>
                    <FilterForm/>
                </Grid>
                <Grid item xs={11}>
                    <LoanApplicationsByStatus {...filterValues}/>
                </Grid>
                <Grid item xs={11}>
                    <LoanApplicationsByLoanType {...filterValues}/>
                </Grid>
            </Grid>
        </Form>
    )
}
