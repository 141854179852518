import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    NumberField,
    EditButton, Edit,
    Create, SimpleForm,
    TextInput, NumberInput,
    CreateButton, TopToolbar,
    SaveButton, Toolbar,
    DeleteWithConfirmButton,
    BooleanField,
} from 'react-admin';

import {AdminPortalRoles} from "./Security";
import {CheckPermission} from "../security/CheckPermission";
import {Grid} from "@mui/material";
import RichTextEditor, {RICH_TEXT_ENTITIES} from "../controls/RichTextEditor";

export const ListActions = () => (
    <TopToolbar>
        <CheckPermission role={AdminPortalRoles.BACKOFFICE_ADMIN}>
            <CreateButton/>
        </CheckPermission>
    </TopToolbar>
);

export const DictList = (props) => (
    <List {...props} actions={<ListActions/>} sort={{field: "sortOrder", order: "ASC"}} perPage={50}>
        <Datagrid rowClick={false} bulkActionButtons={false}>
            <TextField source="code" />
            <TextField source="name" />
            <TextField source="description" />
            <NumberField source="sortOrder" />
            <CheckPermission role={AdminPortalRoles.BACKOFFICE_ADMIN}>
                <EditButton />
            </CheckPermission>
        </Datagrid>
    </List>
);

export const EditToolbar = props => {
    return <Toolbar {...props}>
        <CheckPermission role={AdminPortalRoles.BACKOFFICE_ADMIN}>
            <SaveButton/>
            <DeleteWithConfirmButton
                confirmContent="You will not be able to recover this record. Are you sure?"
                translateOptions={{ name: "dictionary item" }}
            />
        </CheckPermission>
    </Toolbar>
};

export const DictEdit = (props) => (
    <Edit {...props}>
        <SimpleForm toolbar={<EditToolbar/>}>
            <TextInput source="code" name="code" required={true}/>
            <TextInput source="name" name="name" required={true}/>
            <TextInput source="description" name="description"/>
            <NumberInput source="sortOrder" name="sortOrder" />
        </SimpleForm>
    </Edit>
);

export const DictCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="code" name="code" required={true}/>
            <TextInput source="name" name="name" required={true}/>
            <TextInput source="description" name="description"/>
            <NumberInput source="sortOrder" name="sortOrder" />
        </SimpleForm>
    </Create>
);

export const ExtApisList = (props) => (
    <List {...props} actions={<TopToolbar/>} sort={{field: "sortOrder", order: "ASC"}} perPage={50}>
        <Datagrid rowClick={false} bulkActionButtons={false}>
            <TextField source="code" />
            <TextField source="name" />
            <TextField source="description" />
            <TextField source="url"/>
        </Datagrid>
    </List>
);

export const CallReportTemplateEdit = (props) => (
    <Edit {...props} redirect="list">
        <SimpleForm toolbar={<EditToolbar/>}>
            <Grid container spacing={1}>
                <Grid item xs={4}>
                    <TextInput source="code" name="code" required disabled fullWidth/>
                </Grid>
                <Grid item xs={4}>
                    <TextInput source="name" name="name" required fullWidth/>
                </Grid>
                <Grid item xs={2}>
                    <NumberInput source="sortOrder" name="sortOrder" />
                </Grid>
                <Grid item xs={12}>
                    <RichTextEditor entityType={RICH_TEXT_ENTITIES.CALL_REPORT_TEMPLATES_DICT}
                                    entityId="-"
                                    placeholder="Content of the communication"
                                    label="Content of the communication"
                                    source="description" />
                </Grid>
            </Grid>
        </SimpleForm>
    </Edit>
);

export const CallReportTemplateCreate = (props) => (
    <Create {...props} redirect="list">
        <SimpleForm>
            <Grid container spacing={1}>
                <Grid item xs={4}>
                    <TextInput source="name" name="name" required fullWidth/>
                </Grid>
                <Grid item xs={2}>
                    <NumberInput source="sortOrder" name="sortOrder" />
                </Grid>
                <Grid item xs={12}>
                    <RichTextEditor entityType={RICH_TEXT_ENTITIES.CALL_REPORT_TEMPLATES_DICT}
                                    entityId="-"
                                    placeholder="Content of the communication"
                                    label="Content of the communication"
                                    source="description" />
                </Grid>
            </Grid>
        </SimpleForm>
    </Create>
);


export const CallReportTemplateList = (props) => (
    <List {...props} actions={<ListActions/>} sort={{field: "sortOrder", order: "ASC"}} perPage={50}>
        <Datagrid rowClick={false} bulkActionButtons={false}>
            <TextField source="code" />
            <TextField source="name" />
            <BooleanField source="isContentExists"/>
            <NumberField source="sortOrder" />
            <CheckPermission role={AdminPortalRoles.BACKOFFICE_ADMIN}>
                <EditButton />
            </CheckPermission>
        </Datagrid>
    </List>
);