import {List, RadioButtonGroupInput, TextInput} from "react-admin";
import PersonsDatagrid from "./PersonsDatagrid";
import * as React from "react";

const personsFilters = [
    // eslint-disable-next-line react/jsx-key
    <TextInput source="name" alwaysOn label="First or Last Name"/>,
    // eslint-disable-next-line react/jsx-key
    <TextInput source="email" alwaysOn />,
    // eslint-disable-next-line react/jsx-key
    <TextInput source="userLoginLike" alwaysOn />,
    // eslint-disable-next-line react/jsx-key
    <RadioButtonGroupInput label="Person type" source="monivoltRep" alwaysOn choices={[
        { id: '', name: 'All' },
        { id: 'true', name: 'Monivolt Rep' },
    ]} />,
];

function PersonsListPage(props) {
    return (
        <List filters={personsFilters} sort={{field: "id", order: "DESC"}} perPage={50}>
            <PersonsDatagrid />
        </List>
    );
}

export default PersonsListPage;
